import {
  Checklist,
  FormCheckbox,
  FormTextField,
  TextSecondary,
  Wrapper,
} from "components";
import React, { ReactNode, useEffect, useState } from "react";
import logo from "assets/logo-white.webp";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Text,
} from "@radix-ui/themes";
import { useForm } from "react-hook-form";
import { useAuthContext } from "features/auth/useAuthContext";
import cards from "constants/cards";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "stripeConfig";
import useCheckoutParams from "hooks/useCheckoutParams";
import { Item } from "features/checkout/types";

interface Form {
  name: string;
  surname: string;
  invoice: boolean;
  postalCode?: number;
  municipality?: string;
  address?: string;
}

interface myCollapsibleController {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}
const useMyCollapsibleController = (
  defaultIndex: myCollapsibleController["index"]
) => {
  const [index, setIndex] = useState<number>(defaultIndex);
  return { index, setIndex };
};

const MyCollapsible = ({
  index,
  controller,
  header,
  children,
}: {
  index: myCollapsibleController["index"];
  controller: myCollapsibleController;
  header: ReactNode;
  children: ReactNode;
}) => {
  const current: boolean = controller.index === index;
  return (
    <Box>
      <Box
        onClick={() => {
          controller.setIndex(index);
        }}
        py="3"
        style={{ opacity: current ? "1" : "0.4" }}
      >
        <Flex gap="4" align="center">
          <Avatar fallback={index} />
          <Box style={{ flex: 1 }}>{header}</Box>
        </Flex>
      </Box>
      <Box display={current ? "block" : "none"} py="4">
        {children}
      </Box>
    </Box>
  );
};

const CheckoutPage = () => {
  const { user } = useAuthContext();
  const { pack, plan, isPlan } = useCheckoutParams();
  const myCollapsibleController = useMyCollapsibleController(1);
  const {
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<Form>();

  const goToLastStep = () => {
    myCollapsibleController.setIndex(3);
  };

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        surname: user.surname,
        invoice: false,
      });
    }
  }, [user, reset]);

  const name = watch("name");
  const surname = watch("surname");
  const invoice = watch("invoice");

  if (!pack && !plan) return <>Impossibile recuperare il prodotto</>;

  const currencySymbol = {
    eur: "€",
  };

  const item = {
    _id: pack?._id ?? plan?._id,
    name: pack?.name ?? plan?.name,
    features: (pack && cards[pack.card]?.features) ?? plan?.features ?? [],
    interval: plan?.interval,
    currency: currencySymbol[pack?.currency ?? plan?.currency ?? "eur"],
    initialAmount: pack?.initialAmount ?? plan?.initialAmount ?? 0,
    amount: pack?.amount ?? plan?.amount ?? 0,
    discountPercentage: 0,
    type: pack ? "pack" : "plan",
  };

  item.discountPercentage = Math.round(
    ((item.initialAmount - item.amount) / item.initialAmount) * 100
  );

  return (
    <Wrapper size="sm">
      <Box style={{ textAlign: "center" }} p="6">
        <img src={logo} alt="logo" width={160} />
      </Box>
      <Card>
        <Box p="1">
          {/* <Heading mb="2">Checkout</Heading> */}
          <MyCollapsible
            header={
              <>
                <Flex justify="between">
                  <Heading size="3">{item.name}</Heading>
                </Flex>
                {!!item.discountPercentage && (
                  <Badge color="green" mt="1">
                    Sconto del {item.discountPercentage}%
                  </Badge>
                )}
                {plan && (
                  <Badge color="green" mt="1">
                    Solo {item.currency}
                    {item.amount / 100}
                  </Badge>
                )}
              </>
            }
            index={1}
            controller={myCollapsibleController}
          >
            <Checklist list={item.features} />
            <Flex mt="4" gap="2">
              <TextSecondary style={{ flex: 1 }}>Totale:</TextSecondary>
              {!!item.discountPercentage && (
                <TextSecondary style={{ textDecoration: "line-through" }}>
                  <TextSecondary size="2">{item.currency}&nbsp;</TextSecondary>
                  {(item.initialAmount / 100).toFixed(2)}
                </TextSecondary>
              )}
              <Text>
                <TextSecondary size="2">{item.currency}&nbsp;</TextSecondary>
                {(item.amount / 100).toFixed(2)}
                {item.interval === "month" && (
                  <TextSecondary size="2">/me</TextSecondary>
                )}
              </Text>
            </Flex>

            <Button
              size="3"
              onClick={goToLastStep}
              variant="soft"
              style={{ width: "100%" }}
              mt="3"
            >
              Continua
            </Button>
          </MyCollapsible>

          {myCollapsibleController.index !== 1 && (
            <>
              <MyCollapsible
                header={
                  <>
                    <Heading size="3">
                      {name} {surname}
                    </Heading>
                    <TextSecondary size="2">
                      {invoice ? "Fattura" : "Ricevuta fiscale"}
                    </TextSecondary>
                  </>
                }
                index={2}
                controller={myCollapsibleController}
              >
                <Flex direction="column" gap="4">
                  <FormTextField
                    name="name"
                    control={control}
                    placeholder="Nome"
                    required
                  />
                  <FormTextField
                    name="surname"
                    control={control}
                    placeholder="Cognome"
                    required
                  />
                  <Flex justify="end">
                    <FormCheckbox
                      name="invoice"
                      text="Richiedi fattura"
                      control={control}
                      inverse
                    />
                  </Flex>
                  {invoice && (
                    <>
                      <FormTextField
                        name="postalCode"
                        type="number"
                        control={control}
                        label="CAP"
                        required={invoice}
                      />
                      <FormTextField
                        name="municipality"
                        control={control}
                        label="Comune"
                        required={invoice}
                      />
                      <FormTextField
                        name="address"
                        control={control}
                        label="Indirizzo"
                        required={invoice}
                      />
                    </>
                  )}

                  <Button
                    size="3"
                    onClick={goToLastStep}
                    variant="soft"
                    style={{ width: "100%" }}
                    mt="3"
                  >
                    Continua
                  </Button>
                </Flex>
              </MyCollapsible>
              <MyCollapsible
                header={<Heading size="3">Pagamento</Heading>}
                index={3}
                controller={myCollapsibleController}
              >
                {item._id && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm item={item as Item} />
                  </Elements>
                )}
              </MyCollapsible>
            </>
          )}
        </Box>
      </Card>
      {/* <Box pt="4" style={{ textAlign: "center", opacity: 1 }}>
        <img src={PaymentCards} alt="" height={35} />
      </Box> */}
    </Wrapper>
  );
};

export default CheckoutPage;
