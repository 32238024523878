import { Badge, Flex, Text } from "@radix-ui/themes";
import { useState } from "react";
import { Day } from "features/user/types";
import dayjs from "dayjs";
import "dayjs/locale/it";

dayjs.locale("it");

interface InlineCalendarProps {
  onChange: (day: Day) => void;
}

const InlineCalendar = ({ onChange }: InlineCalendarProps) => {
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => ({
    key: dayjs().locale("en").day(i).format("ddd").toLowerCase(),
    label: dayjs().locale("it").day(i).format("ddd"),
  })) as { key: Day; label: string }[];
  const [selectedDay, setSelectedDay] = useState<Day>(
    dayjs().locale("en").format("ddd").toLowerCase() as Day
  );

  return (
    <Flex direction="row" justify="between" px="3">
      {[...daysOfWeek.slice(1), daysOfWeek[0]].map(({ key, label }) => (
        <Flex
          key={key}
          direction="column"
          gap="2"
          align="center"
          onClick={() => {
            setSelectedDay(key);
            onChange(key);
          }}
        >
          <Text size="1" weight="bold">
            {label}
          </Text>
          <Badge
            variant={key === selectedDay ? "solid" : "soft"}
            color={key === selectedDay ? undefined : "gray"}
            size="1"
            style={{ fontSize: "10px" }}
          >
            2
          </Badge>
        </Flex>
      ))}
    </Flex>
  );
};

export default InlineCalendar;
