import { usePracticeQuery } from "../../features/practices/usePracticeQuery";
import { useAuthContext } from "../../features/auth/useAuthContext";
import { Box, Card, Flex, Grid, Heading, TextField } from "@radix-ui/themes";
import { TextSecondary, Wrapper } from "components";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { styled } from "@stitches/react";
import { useMemo, useState } from "react";
import CircleProgress from "components/CircleProgress";
import PracticeCard from "components/PracticeCard";

interface ProgressCardProps {
  percentage?: number;
}

const ProgressCard = ({ percentage = 0 }: ProgressCardProps) => {
  const StyledCard = styled(Card, {
    backgroundColor: "#080037",
  });

  return (
    <StyledCard mb="4" mt="-3">
      <Flex direction="column" gap="3" p="2">
        <Flex gap="2">
          <Heading size="3" style={{ flex: "1" }}>
            Esplora le pratiche e inizia il tuo viaggio trasformativo
          </Heading>
        </Flex>
        <TextSecondary size="2">
          Scopri le altre cards per sbloccare il 100% del tuo percorso e
          raggiungere il tuo massimo potenziale.
        </TextSecondary>
        <Flex gap="3" mt="2" align="center">
          <Box>
            <CircleProgress
              percentage={percentage}
              radius={30}
              strokeWidth={3}
            />
          </Box>
          <Box style={{ flex: "1" }}>
            <Heading size="2">Rilassamento profondo</Heading>
            <TextSecondary size="1">Hai completato X pratiche</TextSecondary>
          </Box>
        </Flex>
        <Flex gap="3" align="center">
          <Box>
            <CircleProgress
              percentage={percentage}
              radius={30}
              strokeWidth={3}
            />
          </Box>
          <Box style={{ flex: "1" }}>
            <Heading size="2">Focus intenso</Heading>
            <TextSecondary size="1">
              Non hai ancora ccompletato alcuna pratica
            </TextSecondary>
          </Box>
        </Flex>
        <Flex gap="3" align="center">
          <Box>
            <CircleProgress
              percentage={percentage}
              radius={30}
              strokeWidth={3}
            />
          </Box>
          <Box style={{ flex: "1" }}>
            <Heading size="2">Energia radiante</Heading>
            <TextSecondary size="1">
              Non hai ancora ccompletato alcuna pratica
            </TextSecondary>
          </Box>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

const HomePage = () => {
  const practicesQuery = usePracticeQuery.useFetchPractices();
  const practicesData = practicesQuery.data;
  const { user } = useAuthContext();
  const [keyword, setKeyword] = useState<string>("");

  const practices = useMemo(
    () =>
      practicesData?.filter((practice) =>
        practice.title?.toLowerCase().includes(keyword.toLowerCase())
      ) ?? [],
    [practicesData, keyword]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.currentTarget.value);
  };

  return (
    <Wrapper size="sm" mt="4">
      <Box pb="6">
        <Heading size="6" align="center">
          Ciao {user?.name}!
        </Heading>
        {/* <TextSecondary as="p" align="center">
          Esplora le pratiche e inizia il tuo viaggio trasformativo
        </TextSecondary> */}
      </Box>
      {user?.purchasedPacksPercentage !== 100 && (
        <ProgressCard percentage={user?.purchasedPacksPercentage} />
      )}
      <Flex gap="2" pb="3">
        
      </Flex>
    </Wrapper>
  );
};

export default HomePage;
