import routes from "../../constants/routes";
import { useAuthContext } from "../../features/auth/useAuthContext";
import { useUserQuery } from "features/user/useUserQuery";
import { useForm } from "react-hook-form";
import { Avatar, Box, Button, Flex, Grid, Heading } from "@radix-ui/themes";
import {
  ButtonList,
  CustomAlertDialog,
  CustomDialog,
  FormTextField,
  useCustomAlertDialog,
  useCustomDialog,
  Wrapper,
} from "components";
import { ExitIcon } from "@radix-ui/react-icons";
import avatars from "constants/avatars";
import PlanCard from "components/PlanCard";

interface Form {
  name: string;
  surname: string;
}

const AccountPage = () => {
  const { user, logout } = useAuthContext();
  const accountDialogState = useCustomDialog();
  const passwordDialogState = useCustomAlertDialog();
  const avatarDialogState = useCustomDialog();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();
  const userQuery = useUserQuery.useUpdateUser();

  const onSubmit = async (data: Form) => {
    try {
      userQuery.mutateAsync(data);
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  if (!user) return <></>;

  return (
    <Wrapper size="sm">
      <Flex align="center" direction="column" gap="1" mb="5">
        <Avatar
          src={avatars[user.avatar]}
          fallback="-"
          size="8"
          onClick={avatarDialogState.open}
        />
        <Heading size="4" mt="3">
          {user.name}
          {user.surname}
        </Heading>
        <Heading size="2">{user.email}</Heading>
      </Flex>
      <Flex direction="column" gap="4">
        <ButtonList
          items={[
            {
              label: "Modifica profilo",
              onClick: accountDialogState.open,
            },
            {
              label: "Reimposta password",
              onClick: () => {
                passwordDialogState.open(null);
              },
            },
            {
              label: "Immagine profilo",
              onClick: avatarDialogState.open,
            },
          ]}
        />
        {!user.hasPaidSubscription && <PlanCard />}

        <ButtonList
          items={[
            {
              label: "Pagamenti",
              to: routes.PAYMENTS,
            },
            {
              label: "Logout",
              onClick: logout,
              icon: <ExitIcon />,
            },
          ]}
        />
      </Flex>
      <Box pb="8"></Box>

      <CustomAlertDialog
        dialogState={passwordDialogState}
        title="Reimposta password"
        description="Clicca su 'Continua' per ricevere via email un link per reimpostare la tua password."
        onConfirm={(item) => {
          passwordDialogState.close();
        }}
      />
      <CustomDialog dialogState={accountDialogState} title="Modifica profilo">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap="4">
            <FormTextField
              name="email"
              control={control}
              placeholder="Indirizzo E-mail"
              required
            />
            <FormTextField
              name="name"
              control={control}
              placeholder="Nome"
              required
            />
            <FormTextField
              name="surname"
              control={control}
              placeholder="Cognome"
              required
            />
            <Button type="submit" size="3">
              Salva
            </Button>
          </Flex>
        </form>
      </CustomDialog>
      <CustomDialog dialogState={avatarDialogState} title="Immagine profilo">
        <Flex direction="column" gap="4">
          <Grid columns="3" gap="3">
            {avatars.map((avatar) => (
              <img src={avatar} alt="avatar" key={avatar} width="100%" />
            ))}
          </Grid>
          <Button type="submit" size="3" onClick={avatarDialogState.close}>
            Salva
          </Button>
        </Flex>
      </CustomDialog>
    </Wrapper>
  );
};

export default AccountPage;
