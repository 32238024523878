import { Link } from "react-router-dom";
import { Box, Card, Flex, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { ReactNode } from "react";

type Item = {
  label: string;
  onClick?: () => void;
  to?: string;
  icon?: ReactNode;
};

interface ButtonListProps {
  items: Item[];
}

const StyledText = styled(Text, {
  padding: "10px 16px",
  display: "block",
  transition: "background .3s",
  fontWeight: "bold",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#ffffff07",
  },
});

const StyledLink = styled(Link, {
  padding: "10px 16px",
  display: "block",
  transition: "background .3s",
  fontWeight: "bold",
  textDecoration: "none",
  color: "inherit",

  "&:hover": {
    backgroundColor: "#ffffff07",
  },
});

const ButtonList = ({ items }: ButtonListProps) => {
  return (
    <Card style={{ padding: 0 }}>
      <Box py="3">
        {items.map((item, index) => (
          <Box key={index}>
            {item.to ? (
              <StyledLink to={item.to}>
                <Flex justify="between" as="span" align="center">
                  {item.label}
                  {item.icon ?? <ChevronRightIcon />}
                </Flex>
              </StyledLink>
            ) : (
              <StyledText onClick={item.onClick}>
                <Flex justify="between" as="span" align="center">
                  {item.label}
                  {item.icon ?? <ChevronRightIcon />}
                </Flex>
              </StyledText>
            )}
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default ButtonList;
