import { Flex, Text } from "@radix-ui/themes";

interface CircleProgressProps {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  hidePercentage?: boolean;
}

const CircleProgress = ({
  percentage,
  radius = 40,
  strokeWidth = 5,
  hidePercentage,
}: CircleProgressProps) => {
  const normalizedRadius = radius - 5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div style={{ position: "relative" }}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#ffffff08"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#C1A2FE"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
          transform={`rotate(-90 ${radius} ${radius})`} // Rotazione di -90 gradi
        />
      </svg>
      <Flex
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
        justify="center"
        align="center"
      >
        <Text weight="bold" size="2" mt="-1">
          {percentage}%
        </Text>
      </Flex>
    </div>
  );
};

export default CircleProgress;
