import React, { ReactNode } from "react";
import { Checkbox, Flex, Text } from "@radix-ui/themes";
import { Control, useController } from "react-hook-form";

interface FormCheckboxProps {
  name: string;
  control: Control<any, any>;
  text?: ReactNode;
  inverse?: boolean;
}

const FormCheckbox = ({
  name,
  control,
  text,
  inverse = false,
}: FormCheckboxProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <div>
      <Text as="label" size="2">
        <Flex gap="2">
          {inverse && text}
          <Checkbox {...field} onCheckedChange={field.onChange} size="3" />
          {!inverse && text}
        </Flex>
      </Text>
      {error?.message}
    </div>
  );
};

export default FormCheckbox;
