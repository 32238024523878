import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthLayout from "layouts/AuthLayout";

const SignupSuccessPage = () => {
  return (
    <AuthLayout title="Registrazione avvenuta con successo">
      okkkkk
      <Link to={routes.LOGIN}>Accedi da qui</Link>
    </AuthLayout>
  );
};

export default SignupSuccessPage;
