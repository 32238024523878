import { CheckIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { Box, Button, Flex, Heading, Separator } from "@radix-ui/themes";
import { TextSecondary } from "components";
import { Card } from "constants/cards";
import routes from "constants/routes";
import { Link as RouterLink } from "react-router-dom";

interface PackPaywallProps {
  card: Card;
}

const PackPaywall = ({ card }: PackPaywallProps) => {
  return (
    <Flex direction="column" align="center" mx="-3">
      <LockClosedIcon width="60px" height="60px" />
      <Heading size="4" mt="4" mb="-">
        Sblocca questa card ora!
      </Heading>
      <TextSecondary as="p" align="center">
        Ottieni la card {card.name} e accedi subito a questo e molti altri
        contenuti esclusivi
      </TextSecondary>

      <Separator mb="4" style={{ width: "90%" }} />
      {card.paywall.features.map((feature) => (
        <Flex key={feature.title} gap="2" mb="4">
          <Box>
            <CheckIcon width="28px" height="28px" color="green" />
          </Box>
          <Box>
            <Heading size="3" mb="1">
              {feature.title}
            </Heading>
            <TextSecondary size="2">{feature.description}</TextSecondary>
          </Box>
        </Flex>
      ))}
      <Separator mb="4" style={{ width: "90%" }} />

      <RouterLink
        to={routes.CHECKOUT_ITEM(card._id, "pack")}
        style={{ textDecoration: "none" }}
      >
        <Button size="4">Ottienila ora</Button>
      </RouterLink>
    </Flex>
  );
};

export default PackPaywall;
