import React from "react";
import { Box, TextField } from "@radix-ui/themes";
import { Control, FieldError, useController } from "react-hook-form";
import TextSecondary from "./TextSecondary";

interface FormTextFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  type?: "number" | "text" | "email" | "password";
  required?: boolean;
}

const FormTextField = ({
  name,
  control,
  label,
  placeholder,
  type = "text",
  required,
  ...rest
}: FormTextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <div>
      {label && (
        <Box mb="1">
          <TextSecondary as="label" size="3">
            {label}
          </TextSecondary>
        </Box>
      )}
      <TextField.Root
        type={type}
        placeholder={label ?? placeholder}
        {...field}
        size="3"
        required={required}
      />
      {error?.message}
    </div>
  );
};

export default FormTextField;
