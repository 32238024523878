import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "@radix-ui/themes/styles.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./features/auth/useAuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { Theme } from "@radix-ui/themes";
import { PacksProvider } from "features/packs/usePacksContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Theme
    accentColor="iris"
    grayColor="sand"
    radius="large"
    scaling="110%"
    appearance="dark"
    panelBackground="translucent"
  >
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <PacksProvider>
          <App />
        </PacksProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Theme>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
