import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import PracticesPage from "./pages/PracticesPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import AccountPage from "./pages/AccountPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { useAuthContext } from "./features/auth/useAuthContext";
import PracticePage from "./pages/PracticesPage/PracticePage";
import routes from "./constants/routes";
import PaymentsPage from "./pages/PaymentsPage";
import SignupSuccessPage from "./pages/SignupPage/SignupSuccessPage";
import ScrollToTop from "components/ScrollToTop";
import CheckoutPage from "pages/CheckoutPage";
import { TextSecondary } from "components";
import RoutinePage from "pages/RoutinePage";
import CheckoutSuccessPage from "pages/CheckoutPage/CheckoutSuccessPage";
import PackPage from "pages/PracticesPage/PackPage";
import HomePage from "pages/PracticesPage/HomePage";

const App = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path={routes.LOGIN} element={<LoginPage />} />
            <Route path={routes.SIGNUP} element={<SignupPage />} />
            <Route
              path={routes.SIGNUP_SUCCESS}
              element={<SignupSuccessPage />}
            />
            <Route
              path={routes.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />
          </>
        ) : (
          <Route path="*" element={<Navigate to={routes.HOME} />} />
        )}
        {isAuthenticated ? (
          <>
            <Route
              path={routes.HOME}
              element={
                <MainLayout>
                  <HomePage />
                </MainLayout>
              }
            />
            <Route
              path={routes.PACK(":packId")}
              element={
                <MainLayout>
                  <PackPage />
                </MainLayout>
              }
            />
            <Route
              path={routes.PRACTICE_DETAIL(":practiceId")}
              element={
                <MainLayout>
                  <PracticePage />
                </MainLayout>
              }
            />
            <Route
              path={routes.ROUTINE}
              element={
                <MainLayout>
                  <RoutinePage />
                </MainLayout>
              }
            />
            <Route
              path={routes.ACCOUNT}
              element={
                <MainLayout>
                  <AccountPage />
                </MainLayout>
              }
            />
            <Route
              path={routes.PAYMENTS}
              element={
                <MainLayout>
                  <PaymentsPage />
                </MainLayout>
              }
            />
            <Route path={routes.CHECKOUT} element={<CheckoutPage />} />
            <Route
              path={routes.CHECKOUT_SUCCESS}
              element={<CheckoutSuccessPage />}
            />
          </>
        ) : (
          <Route path="*" element={<Navigate to={routes.LOGIN} />} />
        )}
      </Routes>
      <TextSecondary
        style={{
          fontSize: 8,
          pointerEvents: "none",
          position: "absolute",
          bottom: 2,
          right: 4,
          opacity: 0.3,
        }}
      >
        v0.0.0
      </TextSecondary>
    </Router>
  );
};

export default App;
