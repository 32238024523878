import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Box, Button, Flex } from "@radix-ui/themes";
import { TextSecondary } from "components";
import { checkoutApi } from "features/checkout/checkoutApi";
import { useNavigate } from "react-router-dom";
import routes from "constants/routes";
import { Item } from "features/checkout/types";

interface CheckoutFormProps {
  item: Item;
}

const CheckoutForm = ({ item }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const stripeCardStyle = {
    style: {
      base: {
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#fff",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const createPaymentIntent = async () => {
    try {
      const { clientSecret } = await checkoutApi.createPaymentIntent(item);
      setClientSecret(clientSecret);
    } catch (err) {
      window.alert(JSON.stringify(err));
    }
  };

  const verifyPayment = async (paymentIntentId: string) => {
    try {
      await checkoutApi.verifyPayment({ paymentIntentId });
      setIsLoading(false);
      navigate(routes.CHECKOUT_ITEM_SUCCESS(item._id, item.type));
    } catch (err) {
      setIsLoading(false);
      window.alert(JSON.stringify(err));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (error) {
      console.error("Error:", error);
      setIsLoading(false);
    } else {
      verifyPayment(paymentIntent.id);
    }
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        p="3"
        mb="2"
        style={{ background: "red", color: "#fff", opacity: 0.1 }}
      >
        TEST: <span style={{ userSelect: "all" }}>4242424242424242</span>
      </Box>
      <Box
        py="4"
        px="2"
        style={{ background: "#ffffff0a", borderRadius: "6px" }}
      >
        <CardElement options={stripeCardStyle} />
      </Box>
      <Flex direction="column">
        <TextSecondary as="p" align="center" style={{ fontSize: "12px" }}>
          Cliccando su 'Procedi all'acquisto' confermi di accettare i Termini e
          Condizioni
        </TextSecondary>
        <Button size="4" type="submit" disabled={!stripe || isLoading} loading={isLoading}>
          Procedi all'acquisto
        </Button>
      </Flex>
    </form>
  );
};

export default CheckoutForm;
