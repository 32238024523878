import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Avatar, Box, Flex, IconButton } from "@radix-ui/themes";
import logo from "assets/logo-white.webp";
import { NavigationBar, Wrapper } from "components";
import avatars from "constants/avatars";
import routes from "constants/routes";
import { useAuthContext } from "features/auth/useAuthContext";
import { PacksProvider } from "features/packs/usePacksContext";
import React from "react";
import { Link } from "react-router-dom";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user } = useAuthContext();

  if (!user) return <></>;

  return (
    <Box
      className="main-layout"
      style={{ paddingBottom: "100px", paddingTop: "20px" }}
    >
      {/* <Wrapper size="lg">
        <Flex justify="between" align="center" py="3" px="1">
          <Link to={routes.PRACTICES}>
            <img src={logo} alt="logo" width={160} />
          </Link>
          <Link to={routes.ACCOUNT}>
            <Avatar src={avatars[user.avatar]} fallback="-" />
          </Link>
        </Flex>
      </Wrapper> */}
      {children}
      <NavigationBar />
    </Box>
  );
};

export default MainLayout;
