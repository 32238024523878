import {
  CircleIcon,
  SquareIcon,
  TokensIcon,
  VercelLogoIcon,
} from "@radix-ui/react-icons";
import { Flex, IconProps } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { ReactNode, useMemo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import TextSecondary from "./TextSecondary";
import routes from "constants/routes";
import { usePackQuery } from "features/packs/usePackQuery";
import { usePacksContext } from "features/packs/usePacksContext";

type MenuItem = {
  icon: ReactNode;
  label: string;
  to: string;
};

const StyledNavigationBar = styled(Flex, {
  backgroundColor: "black",
  position: "fixed",
  bottom: 0,
  paddingBottom: "env(safe-area-inset-bottom)",
  width: "100%",
  zIndex: 9999,
});

const StyledItem = styled(RouterLink, {
  flex: 1,
  textDecoration: "none",
  color: "#fff",
  height: "60px",
  //transition: "background-color .3s",

  variants: {
    isActive: {
      true: {
        //backgroundColor: "#ffffff0f",
        "& svg": {
          color: "#c2a3fe",
        },
      },
      false: {
        opacity: "0.7",
        //backgroundColor: "transparent",
      },
    },
  },

  "&:hover": {
    // backgroundColor: "#ffffff0f",
  },

  "& svg": {
    transition: "transform .3s",
    transform: "scale(1)",
    transformOrigin: "center",
  },

  "&:active svg": {
    transform: "scale(0.9)",
  },
});

const iconProps: IconProps = {
  width: "24px",
  height: "24px",
};

const NavigationBar = () => {
  const location = useLocation();
  const { packs } = usePacksContext();

  const items: MenuItem[] = useMemo(() => {
    return [
      {
        icon: <TokensIcon {...iconProps} />,
        label: "Home",
        to: routes.HOME,
      },
      {
        icon: <CircleIcon {...iconProps} />,
        label: "Relax",
        to: routes.PACK(
          packs.find((pack) => pack.card === "relaxation")?._id ?? ""
        ),
      },
      {
        icon: <SquareIcon {...iconProps} />,
        label: "Focus",
        to: routes.PACK(packs.find((pack) => pack.card === "focus")?._id ?? ""),
      },
      {
        icon: <VercelLogoIcon {...iconProps} />,
        label: "Energia",
        to: routes.PACK(
          packs.find((pack) => pack.card === "energy")?._id ?? ""
        ),
      },
      // {
      //   icon: <Component1Icon {...iconProps} />,
      //   label: "Routine",
      //   to: routes.ROUTINE,
      // },
      // {
      //   icon: <PersonIcon {...iconProps} />,
      //   label: "Account",
      //   to: routes.ACCOUNT,
      // },
    ];
  }, []);

  return (
    <StyledNavigationBar direction="row" align="center">
      {items.map(({ label, icon, to }) => (
        <StyledItem key={label} to={to} isActive={location.pathname === to}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            height="100%"
          >
            {icon}
            <TextSecondary
              size="1"
              style={{ fontSize: "10px", marginTop: "3px" }}
            >
              {label}
            </TextSecondary>
          </Flex>
        </StyledItem>
      ))}
    </StyledNavigationBar>
  );
};

export default NavigationBar;
