import React from "react";
import logo from "assets/logo-white.webp";
import { Box, Card, Flex, Heading, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { TextSecondary } from "components";

interface AuthLayoutProps {
  title: string;
  description?: string;
  children: React.ReactNode;
}

const StyledCard = styled(Card, {
  maxWidth: "500px",
  margin: "0 auto",
  //backgroundColor: "black",
});

const AuthLayout = ({ title, description, children }: AuthLayoutProps) => {
  return (
    <>
      <Flex justify="center" py="5">
        <img src={logo} alt="logo" width={200} />
      </Flex>
      <StyledCard>
        <Box p="3">
          <Heading size="8" align="center">
            {title}
          </Heading>
          <TextSecondary weight="medium" align="center" as="p">
            {description}
          </TextSecondary>
          <Box mt="5">{children}</Box>
        </Box>
      </StyledCard>
    </>
  );
};

export default AuthLayout;
