import React from "react";
import { useForm } from "react-hook-form";
import { useAuthQuery } from "../../features/auth/useAuthQuery";
import { Login } from "../../features/auth/types";
import routes from "../../constants/routes";
import { Button, Flex, Separator, Text } from "@radix-ui/themes";
import { FormTextField, TextWithLink } from "../../components";
import AuthLayout from "layouts/AuthLayout";
import { useAuthContext } from "features/auth/useAuthContext";

const LoginPage = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Login>();
  const loginQuery = useAuthQuery.useLogin();
  const { login } = useAuthContext();

  const onSubmit = async (data: Login) => {
    try {
      const { token } = await loginQuery.mutateAsync(data);
      login(token);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <AuthLayout title="Accedi">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="4">
          <FormTextField
            placeholder="Email"
            name="email"
            type="email"
            control={control}
          />
          <FormTextField
            placeholder="Password"
            name="password"
            type="password"
            control={control}
          />
          {loginQuery.isError && (
            <Text size="2" color="red" as="p" align="center">
              {(loginQuery.error as Error)?.message}
            </Text>
          )}
          <Button type="submit" size="3" loading={loginQuery.isLoading}>
            Login
          </Button>
          <TextWithLink
            initialText=""
            linkPath={routes.FORGOT_PASSWORD}
            linkText="Dimenticato la password?"
            size="2"
            align="center"
          />
          <Separator style={{ width: "100%", opacity: 0.2 }} my="2" />
          <TextWithLink
            initialText="Non hai un account?"
            linkPath={routes.SIGNUP}
            linkText="Registrati qui"
            align="center"
          />
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default LoginPage;
