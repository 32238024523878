import { CheckIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import { PaddingProps } from "@radix-ui/themes/dist/cjs/props/padding.props";
import { styled } from "@stitches/react";

const StyledUl = styled("ul", {
  listStyleType: "none",
  margin: 0,
  padding: 0,
});

const StyledLi = styled("li", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "4px 0px",
});

const StyledCheckIcon = styled(CheckIcon, {
  width: "28px",
  height: "28px",
  color: "green",
});

interface ChecklistProps extends PaddingProps {
  list: string[];
}

const Checklist = ({ list, ...padding }: ChecklistProps) => {
  return (
    <Box {...padding}>
      <StyledUl>
        {list.map((item) => (
          <StyledLi key={item}>
            <StyledCheckIcon />
            {item}
          </StyledLi>
        ))}
      </StyledUl>
    </Box>
  );
};

export default Checklist;
