import avatar0 from "assets/mandala.jpg"

const avatars: string[] = [
    avatar0,
    avatar0,
    avatar0,
    avatar0,
    avatar0,
    avatar0,
    avatar0,
    avatar0,
    avatar0,
]

export default avatars;