const routes = {
    LOGIN: "/login",
    SIGNUP: "/signup",
    SIGNUP_SUCCESS: "/signup-success",
    FORGOT_PASSWORD: "/forgot-password",
    HOME: "/",
    PRACTICES: "/practices",
    PRACTICE_DETAIL: (practiceId: string) => `/practices/${practiceId}`,
    ROUTINE: "/routine",
    PACK: (packId: string) => `/packs/${packId}`,
    ACCOUNT: "/account",
    PAYMENTS: "/payments",
    CHECKOUT: "/checkout",
    CHECKOUT_SUCCESS: "/checkout/success",
    CHECKOUT_ITEM: (id: string, type: "plan" | "pack") => `/checkout?${type === "plan" ? `planId=${id}` : `packId=${id}`}`,
    CHECKOUT_ITEM_SUCCESS: (id: string, type: "plan" | "pack") => `/checkout/success?${type === "plan" ? `planId=${id}` : `packId=${id}`}`,
};

export default routes;