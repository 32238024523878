import { ChevronDownIcon, Heading, IconProps } from "@radix-ui/themes";
import { keyframes, styled } from "@stitches/react";
import React, {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
} from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  backgroundColor: "#ffffff08",
  padding: "10px 0px",
});

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "transparent",
  padding: "16px 20px",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 15,
  lineHeight: 1,
  '&[data-state="open"]': { backgroundColor: "#ffffff08" },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",
  fontSize: 15,

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

const StyledChevron = styled(ChevronDownIcon, {
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof StyledTrigger> {
  children: React.ReactNode;
}

const Accordion = StyledAccordion;
const AccordionItem = StyledItem;

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps & {
    icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
    onClick?: () => void;
  }
>(
  (
    { children, icon: CustomIcon, onClick, disabled, ...props },
    forwardedRef
  ) => (
    <StyledHeader onClick={disabled ? onClick : () => {}}>
      <StyledTrigger
        {...props}
        ref={forwardedRef}
        style={disabled ? { pointerEvents: "none" } : {}}
      >
        {children}
        {CustomIcon ? (
          <CustomIcon aria-hidden />
        ) : (
          <StyledChevron aria-hidden />
        )}
      </StyledTrigger>
    </StyledHeader>
  )
);

interface AccordionContentProps
  extends React.ComponentPropsWithoutRef<typeof StyledContent> {
  children: React.ReactNode;
}

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    {children}
  </StyledContent>
));

export const CustomAccordionRoot = ({ children }: { children: ReactNode }) => (
  <Accordion type="single" defaultValue="item-1" collapsible>
    {children}
  </Accordion>
);

export const CustomAccordionItem = ({
  title,
  children,
  value,
  onClick,
  icon,
  disabled,
}: {
  title: string;
  children: ReactNode;
  value: string;
  onClick?: () => void;
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  disabled?: boolean;
}) => (
  <AccordionItem value={value}>
    <AccordionTrigger onClick={onClick} icon={icon} disabled={disabled}>
      <Heading size="3" weight="medium">
        {title}
      </Heading>
    </AccordionTrigger>
    <AccordionContent>
      <div style={{ padding: "16px" }}>{children}</div>
    </AccordionContent>
  </AccordionItem>
);
