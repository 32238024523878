import { CardType } from "features/packs/types";

type Paywall = {
    features: {
        title: string;
        description: string;
    }[];
};

export type Card = {
    _id: string;
    name: string;
    image: string;
    paywall: Paywall;
    features: string[];
};

const features: string[] = [
    "Accesso completo alla tua card",
    "Esercizi di respirazione guidati",
    "Pratiche meditative guidate",
]

const cards: Record<CardType, Card> = {
    focus: {
        _id: '66faf14c3b0a6201d047ad88',
        name: "Focus intenso",
        image: "https://holoscards.com/wp-content/uploads/2024/09/focus-intenso.png",
        features,
        paywall: {
            features: [
                {
                    title: "Pratiche meditative",
                    description:
                        "Immergiti in pratiche meditative guidate per affinare la concentrazione. Una voce ti conduce verso la chiarezza mentale, lasciando spazio alla lucidità.",
                },
                {
                    title: "Esercizi di respirazione",
                    description:
                        "Scopri esercizi di respirazione guidati per migliorare la concentrazione. Ogni respiro potenzia la tua attenzione e rafforza la tua capacità di focus.",
                },
            ],
        }
    },
    energy: {
        _id: '66faf14c3b0a6201d047ad89',
        name: "Energia radiante",
        image: "https://holoscards.com/wp-content/uploads/2024/09/energia-radiante.png",
        features,
        paywall: {
            features: [
                {
                    title: "Pratiche meditative",
                    description:
                        "Immergiti in pratiche meditative energizzanti, dove una luce vitale ti riempie di vitalità. Un’esperienza che rinvigorisce mente e corpo.",
                },
                {
                    title: "Esercizi di respirazione",
                    description:
                        "Scopri esercizi di respirazione che aumentano l’energia. Ogni respiro rivitalizza il corpo, portando nuova vitalità e slancio.",
                },
            ],
        }
    },
    relaxation: {
        _id: '66faf14c3b0a6201d047ad87',
        name: "Rilassamento Profondo",
        image: "https://holoscards.com/wp-content/uploads/2024/09/rilassamento-profondo.png",
        features,
        paywall: {
            features: [
                {
                    title: "Pratiche meditative",
                    description:
                        "Immergiti in pratiche meditative guidate, dove la mente trova serenità. Un viaggio interiore ti avvolge in un abbraccio di calma e rilassamento.",
                },
                {
                    title: "Esercizi di respirazione",
                    description:
                        "Esplora esercizi di respirazione guidati che liberano la mente e rinvigoriscono il corpo. Ogni respiro porta chiarezza e serenità al tuo essere.",
                },
            ],
        }
    },
};

export default cards;