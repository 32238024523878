import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthLayout from "layouts/AuthLayout";
import { FormTextField, TextWithLink } from "components";
import { Button, Flex } from "@radix-ui/themes";

interface ForgotPasswordFormInputs {
  email: string;
}

const ForgotPasswordPage = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>();

  const onSubmit = (data: ForgotPasswordFormInputs) => {
    console.log(data);
  };

  return (
    <AuthLayout title="Recupero Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="4">
          <FormTextField name="email" control={control} required />
          <Button type="submit" size="3">
            Recupera Password
          </Button>
          <TextWithLink
            initialText=""
            linkPath={routes.LOGIN}
            linkText="Torna alla login"
            align="center"
          />
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
