import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { User } from "../user/types";
import { useUserQuery } from "features/user/useUserQuery";
import { JWT_VARIABLE } from "api";
import LoadingPage from "pages/LoadingPage";

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const userQuery = useUserQuery.useFetchUser(isAuthenticated);

  const login = (token: string) => {
    window.localStorage.setItem(JWT_VARIABLE, token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    window.localStorage.removeItem(JWT_VARIABLE);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (!userQuery.data || userQuery.isError) return setIsAuthenticated(false);
    setUser(userQuery.data);
  }, [userQuery.isError, userQuery.data]);

  useEffect(() => {
    if (window.localStorage.getItem(JWT_VARIABLE)) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {userQuery.isLoading ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
