const endpoints = {
    AUTH_LOGIN: "/auth/login",
    AUTH_SIGNUP: "/auth/signup",
    AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
    PRACTICES: "/practices",
    PRACTICE_DETAIL: (practiceId: string) => `/practices/${practiceId}`,
}

export default endpoints;

