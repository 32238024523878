import { Box, Card, Checkbox, Flex, Heading, Text } from "@radix-ui/themes";
import { Practice } from "features/practices/types";
import TextSecondary from "./TextSecondary";
import routes from "constants/routes";
import BreathingIcon from "assets/breathing.svg";
import MeditationIcon from "assets/meditation.svg";
import { styled } from "@stitches/react";
import { Link as RouterLink } from "react-router-dom";

interface PracticeCardProps {
  practice: Practice;
  variant?: "default" | "basic";
}

const PracticeCard = ({ practice, variant = "default" }: PracticeCardProps) => {
  const info: Array<"duration" | "frequency"> = ["duration", "frequency"];

  const StyledLink = styled(RouterLink, {
    display: "block",
    textDecoration: "none",
    color: "inherit",
    transition: "transform .3s",

    "&:hover": {
      transform: "scale(1.03)",
    },
  });

  const icons: Record<Practice["type"], string> = {
    meditation: MeditationIcon,
    breathing: BreathingIcon,
  };

  const image = (
    <img
      src={icons[practice.type]}
      alt="icon"
      width="30px"
      style={{ verticalAlign: "middle" }}
    />
  );

  return (
    <StyledLink to={routes.PRACTICE_DETAIL(practice._id)}>
      {variant === "default" ? (
        <Card>
          <Box p="2">
            <Flex justify="between" gap="1">
              <Heading size="3">{practice.title}</Heading>
              {/* <IconButton variant="ghost" size="2" mt="1">
                <StarIcon color="#ffffff20" />
              </IconButton> */}
            </Flex>
            <Flex justify="between" align="center" mt="4">
              {info.map((key) => (
                <Flex direction="column" gap="1" key={key} align="center">
                  <Text weight="bold" size="2">
                    {practice[key].value}
                  </Text>
                  <TextSecondary weight="medium" size="1">
                    {practice[key].unit}
                  </TextSecondary>
                </Flex>
              ))}
              <Box>
                <Card>
                  {/* <LockClosedIcon /> */}
                  {image}
                </Card>
              </Box>
            </Flex>
          </Box>
        </Card>
      ) : (
        <Card>
          <Box p="0">
            <Flex gap="3" align="center">
              <Box>
                <Card>
                  <img
                    src={icons[practice.type]}
                    alt="icon"
                    width="30px"
                    style={{ verticalAlign: "middle" }}
                  />
                </Card>
              </Box>
              <Heading size="3" style={{ flex: 1 }}>
                {practice.title}
              </Heading>
            </Flex>
          </Box>
        </Card>
      )}
    </StyledLink>
  );
};

export default PracticeCard;
