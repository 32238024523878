import { Cross1Icon } from "@radix-ui/react-icons";
import { Button, Dialog, Flex, IconButton, Separator } from "@radix-ui/themes";
import React, { useState } from "react";

type CustomAlertDialogState<T> = {
  isOpen: boolean;
  open: (item: T) => void;
  close: () => void;
  item: T | null;
};

export const useCustomAlertDialog = <T,>(): CustomAlertDialogState<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [item, setItem] = useState<T | null>(null);

  const open = (item: T) => {
    setIsOpen(true);
    setItem(item);
  };
  const close = () => setIsOpen(false);

  return { open, close, isOpen, item };
};

interface CustomAlertDialogProps<T> {
  dialogState: CustomAlertDialogState<T>;
  title: string;
  description: string;
  onConfirm: (item: T | null) => void;
}

const CustomAlertDialog = <T,>({
  dialogState,
  title,
  description,
  onConfirm,
}: CustomAlertDialogProps<T>) => {
  const onDialogConfirm = () => {
    onConfirm(dialogState.item);
  };

  return (
    <Dialog.Root open={dialogState.isOpen}>
      <Dialog.Content maxWidth="450px">
        <Flex direction="row" justify="between">
          <Dialog.Title>{title}</Dialog.Title>
          <IconButton onClick={dialogState.close} variant="ghost" size="3">
            <Cross1Icon height="20px" width="20px" />
          </IconButton>
        </Flex>
        {description && (
          <Dialog.Description size="2" mb="4">
            {description}
          </Dialog.Description>
        )}
        <Separator style={{ width: "100%", opacity: 0.3 }} />
        <Flex gap="3" mt="4" mb="-2">
          <Button
            variant="ghost"
            size="3"
            color="gray"
            onClick={dialogState.close}
            style={{ flex: 1 }}
          >
            Annulla
          </Button>
          <Button
            variant="ghost"
            size="3"
            color="red"
            onClick={onDialogConfirm}
            style={{ flex: 1 }}
          >
            Continua
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CustomAlertDialog;
