import { Plan } from './types';
import api from 'api';

const fetchPlans = (): Promise<Plan[]> =>
    api.get('/plans');

const fetchPlan = ({ planId }: { planId: Plan["_id"] }): Promise<Plan> =>
    api.get(`/plans/${planId}`);

export const planApi = {
    fetchPlans,
    fetchPlan,
};