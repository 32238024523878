import { usePracticeQuery } from "../../features/practices/usePracticeQuery";
import { Flex, Grid, Heading, TextField } from "@radix-ui/themes";
import { Wrapper } from "components";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import PracticeCard from "components/PracticeCard";
import { useParams } from "react-router-dom";
import { usePacksContext } from "features/packs/usePacksContext";
import cards from "constants/cards";

const PackPage = () => {
  const { packId } = useParams();
  const practicesQuery = usePracticeQuery.useFetchPractices({ packId });
  const practicesData = practicesQuery.data;
  const { packs } = usePacksContext();
  const pack = useMemo(
    () => packs.find((pack) => pack._id === packId),
    [packs, packId]
  );
  const [keyword, setKeyword] = useState<string>("");

  const practices = useMemo(
    () =>
      practicesData?.filter((practice) =>
        practice.title?.toLowerCase().includes(keyword.toLowerCase())
      ) ?? [],
    [practicesData, keyword]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.currentTarget.value);
  };

  if (!pack) return <>No pack</>;

  return (
    <Wrapper size="sm" mt="4">
      <Flex align="center" direction="column">
        <img
          src={cards[pack.card].image}
          alt="card"
          style={{ width: "100%", maxWidth: "400px" }}
        />
        <Heading mb="5" size="5">{pack.name}</Heading>
      </Flex>
      <Flex gap="2" pb="3">
        <TextField.Root
          placeholder="Cerca una pratica…"
          size="3"
          style={{ flex: 1 }}
          onChange={handleSearch}
          value={keyword}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
        {/* <Button size="3" variant="outline" color="gray">
          <StarFilledIcon />
          Preferiti
        </Button> */}
        {/* <Button size="3" variant="outline" color="gray">
          <MixerHorizontalIcon />
          Filtri
        </Button> */}
      </Flex>
      {practicesQuery.isLoading ? (
        <div>Loading...</div>
      ) : (
        <Grid gap="3">
          {practices?.map((practice) => (
            <PracticeCard key={practice._id} practice={practice} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};

export default PackPage;
