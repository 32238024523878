import { LockClosedIcon } from "@radix-ui/react-icons";
import { Flex, Heading } from "@radix-ui/themes";
import { TextSecondary } from "components";
import PlanCard from "components/PlanCard";
import { Plan } from "features/plans/types";

type FeatureType = "routine";

interface PlanPaywallProps {
  plan: Plan;
  feature: FeatureType;
}

const PlanPaywall = ({
  plan,
  feature,
}: PlanPaywallProps) => {
  const content: Record<FeatureType, { title: string; description: string }> = {
    routine: {
      title: "Holos Routine",
      description:
        "Questa funzionalità avanzata è inclusa nel piano PRO. Ti permette di organizzare al meglio le tue pratiche preferite.",
    },
  };

  return (
    <Flex direction="column" align="center" mx="-3">
      <LockClosedIcon width="60px" height="60px" />
      <Heading size="4" mt="4" mb="-">
        {content[feature].title}
      </Heading>
      <TextSecondary as="p" align="center">
        {content[feature].description}
      </TextSecondary>
      <PlanCard />
    </Flex>
  );
};

export default PlanPaywall;
