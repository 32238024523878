import { Text, TextProps } from "@radix-ui/themes";
import React from "react";
import { Link } from "react-router-dom";

type TextWithLinkProps = TextProps & {
  initialText: string;
  linkPath: string;
  linkText: string;
};

const TextWithLink = ({
  initialText,
  linkPath,
  linkText,
  ...props
}: TextWithLinkProps) => {
  return (
    <Text as="p" {...props}>
      {initialText}&nbsp;
      <Link to={linkPath}>{linkText}</Link>
    </Text>
  );
};

export default TextWithLink;
