import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthQuery } from "../../features/auth/useAuthQuery";
import { Signup } from "../../features/auth/types";
import routes from "../../constants/routes";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import { FormCheckbox, FormTextField, TextWithLink } from "components";
import { Button, Flex, Separator, Text } from "@radix-ui/themes";

const SignupPage = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Signup>();
  const signupQuery = useAuthQuery.useSignup();
  const navigate = useNavigate();

  const onSubmit = async (data: Signup) => {
    try {
      console.log(data);
      await signupQuery.mutateAsync(data);
      navigate(routes.SIGNUP_SUCCESS);
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };

  return (
    <AuthLayout
      title="Registrati ora"
      description="Registrati per scoprire i percorsi di trasformazione unici racchiusi in ogni card."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="4">
          <FormTextField
            name="email"
            control={control}
            placeholder="Indirizzo E-mail"
            required
          />
          <FormTextField
            name="name"
            control={control}
            placeholder="Nome"
            required
          />
          <FormTextField
            name="surname"
            control={control}
            placeholder="Cognome"
            required
          />
          <FormTextField
            name="password"
            control={control}
            placeholder="Password"
            required
          />
          <FormCheckbox
            name="terms"
            control={control}
            text={
              <Text as="p">
                Ho letto e accetto i&nbsp;
                <Link to="/terms">Termini e Condizioni</Link>
              </Text>
            }
          />
          {signupQuery.isError && (
            <Text size="2" color="red" as="p" align="center">
              {(signupQuery.error as Error)?.message}
            </Text>
          )}
          <Button type="submit" size="3" loading={signupQuery.isLoading}>
            Registrati
          </Button>
          <Separator style={{ width: "100%", opacity: 0.2 }} my="2" />
          <TextWithLink
            initialText="Hai già un account?"
            linkPath={routes.LOGIN}
            linkText="Accedi qui"
            align="center"
          />
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default SignupPage;
