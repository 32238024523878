interface HtmlRendererProps {
  content?: string;
}

const HtmlRenderer = ({ content }: HtmlRendererProps) => {
  if (!content) return <></>;
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HtmlRenderer;
