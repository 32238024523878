import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Card, Flex, Heading, Text } from "@radix-ui/themes";
import { Checklist, TextSecondary } from "components";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { styled } from "@stitches/react";
import { usePlanQuery } from "features/plans/usePlanQuery";
import routes from "constants/routes";

const StyledCard = styled(Card, {
  backgroundColor: "#1d00a4",
});

const PlanCard = () => {
  const planQuery = usePlanQuery.useFetchPlans();
  const plan = planQuery?.data?.[0];

  if (!plan) return <></>;

  return (
    <StyledCard>
      <Flex direction="column" gap="3" p="2">
        <Flex>
          <Box>
            <Heading size="6" mb="2">
              <LightningBoltIcon height={20} width={20} />
              &nbsp;&nbsp;
              {plan.name}
            </Heading>
            <TextSecondary>{plan.description}</TextSecondary>
          </Box>
          <Box>
            <Heading size="8" align="right" mb="-2">
              <Text as="span" size="6">
                €
              </Text>
              {plan.amount / 100}
            </Heading>
            <TextSecondary wrap="nowrap" size="1">
              {plan.interval === "month" ? "al mese" : ""}
            </TextSecondary>
          </Box>
        </Flex>
        <Checklist list={plan.features} />
        <RouterLink
          to={routes.CHECKOUT_ITEM(plan._id, "plan")}
          style={{ width: "100%", textDecoration: "none" }}
        >
          <Button size="3" style={{ width: "100%" }}>
            Ottienilo ora
          </Button>
        </RouterLink>
      </Flex>
    </StyledCard>
  );
};

export default PlanCard;
