import { Flex, Spinner } from "@radix-ui/themes";

const LoadingPage = () => {
  return (
    <Flex align="center" justify="center" minHeight="90vh">
      <Spinner size="3" />
    </Flex>
  );
};

export default LoadingPage;
