import { Cross1Icon } from "@radix-ui/react-icons";
import { Dialog, Flex, IconButton } from "@radix-ui/themes";
import { ReactNode, useState } from "react";

export type CustomDialogState<T = any> = {
  isOpen: boolean;
  open: (state?: T) => void;
  close: () => void;
  state?: T;
};

export const useCustomDialog = <T,>(): CustomDialogState<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState<T>();

  const open = (state?: T) => {
    setIsOpen(true);
    setState(state);
  };
  const close = () => setIsOpen(false);

  return { open, close, isOpen, state };
};

interface CustomDialogProps<T> {
  dialogState: CustomDialogState<T>;
  title?: string;
  description?: string;
  children: ReactNode;
}

const CustomDialog = <T,>({
  dialogState,
  title,
  description,
  children,
}: CustomDialogProps<T>) => {
  return (
    <Dialog.Root open={dialogState.isOpen}>
      <Dialog.Content maxWidth="450px">
        <Flex direction="row" justify="between">
          <Dialog.Title>{title}</Dialog.Title>
          <IconButton onClick={dialogState.close} variant="ghost" size="3">
            <Cross1Icon height="20px" width="20px" />
          </IconButton>
        </Flex>
        {description && (
          <Dialog.Description size="2" mb="4">
            {description}
          </Dialog.Description>
        )}
        {children}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CustomDialog;
