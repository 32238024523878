import { usePracticeQuery } from "../../features/practices/usePracticeQuery";
import { Box, Button, Flex, Heading, IconButton, Text } from "@radix-ui/themes";
import {
  CustomDialog,
  HtmlRenderer,
  TextSecondary,
  useCustomDialog,
  Wrapper,
} from "components";
import { useEffect, useRef, useState } from "react";
import { keyframes, styled } from "@stitches/react";
import {
  Cross1Icon,
  LockClosedIcon,
  TriangleRightIcon,
} from "@radix-ui/react-icons";
import { CustomAccordionItem, CustomAccordionRoot } from "components/Accordion";
import { useParams } from "react-router-dom";
import cards from "constants/cards";
import { Practice } from "features/practices/types";
import PackPaywall from "./PackPaywall";

const expand = keyframes({
  "0%": { transform: "scale(0)", opacity: 1 },
  "100%": { transform: "scale(2)", opacity: 0 },
});

const Circle = styled("div", {
  position: "absolute",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 0.02)",
  animation: `${expand} 9s infinite ease-in-out`,
  width: "100vw",
  height: "100vw",
  left: "-50vw",
  top: "-50vw",
  transformOrigin: "center",
  transform: "scale(0)",
  pointerEvents: "none",
});

const AnimatedCircles = () => {
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      <Circle css={{ animationDelay: "0s" }} />
      <Circle css={{ animationDelay: "3s" }} />
      <Circle css={{ animationDelay: "6s" }} />
    </div>
  );
};

type AudioScreenState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const useAudioScreen = (): AudioScreenState => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { open, close, isOpen };
};

const AudioScreen = ({
  practice,
  controller,
}: {
  practice: Practice;
  controller: AudioScreenState;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const StyledOverlay = styled(Box, {
    backgroundColor: "#000000fc",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    zIndex: 9999991,
    textAlign: "center",
  });

  const zoomIn = keyframes({
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(1.2)" },
  });

  const StyledImgWrapper = styled("div", {
    width: "100%",
    maxWidth: "500px",
    position: "relative",

    "& > img": {
      width: "100%",
      animation: `${zoomIn} 0.5s forwards`,
      transformOrigin: "top",
    },
  });

  useEffect(() => {
    if (controller.isOpen) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
    }
  }, [controller.isOpen]);

  return (
    <StyledOverlay style={{ display: controller.isOpen ? "fixed" : "none" }}>
      <Flex justify="end" direction="row" p="4">
        <IconButton size="4" onClick={controller.close} variant="ghost">
          <Cross1Icon width="24px" height="24px" />
        </IconButton>
      </Flex>
      <Flex align="center" direction="column">
        <StyledImgWrapper>
          {/* <AnimatedCircles /> */}
          <img src={cards[practice.card].image} alt="card" />
        </StyledImgWrapper>
        <audio ref={audioRef} controls>
          {practice.audio ? (
            <source src={practice.audio} type="audio/mpeg" />
          ) : (
            "Audio non disponibile"
          )}
          {!practice.audio && "Il tuo browser non supporta l'elemento audio."}
        </audio>
      </Flex>
    </StyledOverlay>
  );
};

const PracticePage = () => {
  const { practiceId } = useParams<{ practiceId: string }>();
  const practiceQuery = practiceId
    ? usePracticeQuery.useFetchPractice({ practiceId })
    : undefined;
  const practice = practiceQuery?.data;
  const audioScreenController = useAudioScreen();
  const paywallDialog = useCustomDialog();

  return (
    <div>
      {practiceQuery?.isLoading || !practice ? (
        <div>Loading...</div>
      ) : (
        <>
          <AudioScreen controller={audioScreenController} practice={practice} />
          {!audioScreenController.isOpen && (
            <Wrapper size="md">
              <Box p="2" mb="6">
                <Box>
                  <Flex align="center" direction="column">
                    <img
                      src={cards[practice.card].image}
                      alt="card"
                      style={{ width: "100%", maxWidth: "400px" }}
                    />
                  </Flex>
                  <Flex align="center" direction="column" mt="-8" mb="6">
                    <Button
                      size="4"
                      onClick={
                        practice.isPurchased
                          ? audioScreenController.open
                          : paywallDialog.open
                      }
                    >
                      Inizia ora
                      {practice.isPurchased ? (
                        <TriangleRightIcon
                          height="30px"
                          width="30px"
                          style={{ marginLeft: "-8px" }}
                        />
                      ) : (
                        <LockClosedIcon />
                      )}
                    </Button>
                  </Flex>
                  <Heading size="5" align="center">
                    {practice.title}
                  </Heading>
                  <TextSecondary size="4" align="center" as="p">
                    {practice.description}
                  </TextSecondary>
                </Box>
                <Flex justify="between" align="center" my="6" gap="1">
                  <Flex direction="column" gap="1" align="center">
                    <TextSecondary weight="bold" size="2">
                      Durata
                    </TextSecondary>
                    <Text weight="medium" size="2">
                      {practice.duration.value} {practice.duration.unit}
                    </Text>
                  </Flex>
                  <Flex direction="column" gap="1" align="center">
                    <TextSecondary weight="bold" size="2">
                      Frequenza
                    </TextSecondary>
                    <Text weight="medium" size="2">
                      {practice.frequency.value} {practice.frequency.unit}
                    </Text>
                  </Flex>
                  <Flex direction="column" gap="1" align="center">
                    <TextSecondary weight="bold" size="2">
                      Periodo
                    </TextSecondary>
                    <Text weight="medium" size="2">
                      {practice.period}
                    </Text>
                  </Flex>
                </Flex>
                <CustomAccordionRoot>
                  <CustomAccordionItem
                    title="Introduzione e Benefici"
                    value="1"
                    onClick={paywallDialog.open}
                    icon={practice.isPurchased ? undefined : LockClosedIcon}
                    disabled={!practice.isPurchased}
                  >
                    <TextSecondary>
                      <HtmlRenderer content={practice.introduction} />
                    </TextSecondary>
                  </CustomAccordionItem>
                  <CustomAccordionItem
                    title="Consigli Pratici"
                    value="2"
                    onClick={paywallDialog.open}
                    icon={practice.isPurchased ? undefined : LockClosedIcon}
                    disabled={!practice.isPurchased}
                  >
                    <HtmlRenderer content={practice.tips} />
                  </CustomAccordionItem>
                  <CustomAccordionItem
                    title="Errori Comuni"
                    value="3"
                    onClick={paywallDialog.open}
                    icon={practice.isPurchased ? undefined : LockClosedIcon}
                    disabled={!practice.isPurchased}
                  >
                    <HtmlRenderer content={practice.mistakes} />
                  </CustomAccordionItem>
                </CustomAccordionRoot>
              </Box>
            </Wrapper>
          )}
          <CustomDialog dialogState={paywallDialog}>
            <PackPaywall card={cards[practice.card]} />
          </CustomDialog>
        </>
      )}
    </div>
  );
};

export default PracticePage;
