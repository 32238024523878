import { LightningBoltIcon } from "@radix-ui/react-icons";
import { Box, Card, Flex, Heading, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { TextSecondary } from "components";
import { Subscription } from "features/user/types";

const StyledCard = styled(Card, {
  backgroundColor: "#1d00a4",
});

interface SubscriptionCardProps {
  subscription: Subscription;
}

const SubscriptionCard = ({ subscription }: SubscriptionCardProps) => {
  return (
    <StyledCard>
      <Flex direction="column" gap="3" p="2">
        <Flex>
          <Box>
            <Heading size="6" mb="2">
              {subscription.plan.amount !== 0 && (
                <>
                  <LightningBoltIcon height={20} width={20} />
                  &nbsp;&nbsp;
                </>
              )}
              {subscription.plan.name}
            </Heading>
            <TextSecondary>{subscription.plan.description}</TextSecondary>
          </Box>
          <Box>
            <Heading size="8" align="right" mb="-2">
              <Text as="span" size="6">
                €
              </Text>
              {subscription.plan.amount / 100}
            </Heading>
            <TextSecondary wrap="nowrap" size="1">
              {subscription.plan.interval === "month" ? "al mese" : ""}
            </TextSecondary>
          </Box>
        </Flex>
        <Text>{subscription.status}</Text>
        <Text>Si rinnova il {subscription.endDate}</Text>
        {/* <Checklist list={plan.features} /> */}
      </Flex>
    </StyledCard>
  );
};

export default SubscriptionCard;
