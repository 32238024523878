import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userApi } from './userApi';

// const useFetchUser = () => useQuery('user', userApi.fetchUser);

const useFetchUser = (isAuthenticated: boolean) => {
  return useQuery(['user'], userApi.fetchUser, {
    enabled: isAuthenticated,
    retry: 3,
  });
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(userApi.updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
    },
  });
};

const useUpdateRoutine = () => {
  const queryClient = useQueryClient();

  return useMutation(userApi.updateRoutine, {
    onSuccess: () => {
      //queryClient.invalidateQueries(['user']);
    },
  });
};

export const useUserQuery = { useFetchUser, useUpdateUser, useUpdateRoutine };