import { CheckCircledIcon } from "@radix-ui/react-icons";
import { Button, Card, Flex, Heading } from "@radix-ui/themes";
import { TextSecondary, Wrapper } from "components";
import routes from "constants/routes";
import useCheckoutParams from "hooks/useCheckoutParams";
import { Link as RouterLink } from "react-router-dom";

const CheckoutSuccessPage = () => {
  const { pack, plan, isPlan } = useCheckoutParams();
  return (
    <Wrapper size="sm">
      <Card my="6">
        <Flex direction="column" align="center" py="6">
          <CheckCircledIcon width="80px" height="80px" color="green" />
          <Heading size="4" mt="4" align="center">
            {isPlan ? (
              <>Ottimo lavoro, hai attivato {plan?.name}!</>
            ) : (
              <>
                Ottimo lavoro, hai sbloccato la card {pack?.name} e tutti i suoi
                contenuti!
              </>
            )}
          </Heading>
          <TextSecondary as="p" align="center">
            {isPlan ? (
              <>
                La costanza nella pratica quotidiana offre numerosi benefici.
                <br />
                Mantieni la tua routine e prosegui con determinazione!
              </>
            ) : (
              <>
                Ogni pratica ti porta più vicino alla tua crescita personale.
                <br />
                Prosegui con determinazione nel tuo percorso!
              </>
            )}
          </TextSecondary>

          <RouterLink to={routes.HOME} style={{ textDecoration: "none" }}>
            <Button size="3" variant="soft">
              Torna alle pratiche
            </Button>
          </RouterLink>
        </Flex>
      </Card>
    </Wrapper>
  );
};

export default CheckoutSuccessPage;
