import { usePackQuery } from "features/packs/usePackQuery";
import { usePlanQuery } from "features/plans/usePlanQuery";
import { useLocation } from "react-router-dom";

const useCheckoutParams = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const packId = queryParams.get("packId");
    const planId = queryParams.get("planId");
    const isPlan: boolean = !!planId;
    const packQuery = packId ? usePackQuery.useFetchPack({ packId }) : null;
    const pack = packQuery?.data;
    const planQuery = planId ? usePlanQuery.useFetchPlan({ planId }) : null;
    const plan = planQuery?.data;

    return { isPlan, pack, plan };
}

export default useCheckoutParams;