import api from 'api';
import { Item } from './types';

const createPaymentIntent = (item: Item): Promise<{ clientSecret: string }> =>
    api.post('/checkout/create-payment-intent', item);

const verifyPayment = ({ paymentIntentId }: { paymentIntentId: string }): Promise<void> =>
    api.post('/checkout/verify-payment', { paymentIntentId });

export const checkoutApi = {
    createPaymentIntent,
    verifyPayment,
};