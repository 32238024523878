import { Wrapper } from "components";
import { useinvoiceQuery } from "../../features/invoices/useInvoiceQuery";
import { Box, Heading, IconButton, Table } from "@radix-ui/themes";
import { DownloadIcon } from "@radix-ui/react-icons";
import dayjs from "dayjs";
import SubscriptionCard from "./SubscriptionCard";
import { useAuthContext } from "features/auth/useAuthContext";

const PaymentsPage = () => {
  const { user } = useAuthContext();
  const invoicesQuery = useinvoiceQuery.useFetchInvoices();
  const invoices = invoicesQuery.data;

  return (
    <Wrapper size="sm">
      <Heading size="4">Il tuo abbonamento</Heading>
      <Box mt="3">
        <SubscriptionCard subscription={user?.subscription!} />
      </Box>
      {invoicesQuery.data && invoicesQuery.data?.length > 0 && (
        <Box mt="4">
          <Heading size="4">Ricevute fiscali</Heading>
          {invoicesQuery.isLoading ? (
            <div>Loading invoices...</div>
          ) : (
            <Table.Root>
              <Table.Body>
                {invoices?.map((invoice) => (
                  <Table.Row key={invoice._id}>
                    <Table.RowHeaderCell>
                      {invoice.plan?.name ?? invoice.pack?.name}
                    </Table.RowHeaderCell>
                    <Table.Cell>
                      {dayjs(invoice.updatedAt).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell align="right">
                      <IconButton variant="ghost">
                        <DownloadIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

export default PaymentsPage;
