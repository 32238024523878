import { Box, Card, Checkbox, Flex, Heading } from "@radix-ui/themes";
import { Practice } from "features/practices/types";
import BreathingIcon from "assets/breathing.svg";
import MeditationIcon from "assets/meditation.svg";

interface SelectablePracticeCardProps {
  practice: Practice;
  onChange: (value: boolean) => void;
  checked?: boolean;
}

const SelectablePracticeCard = ({
  practice,
  onChange,
  checked = false,
}: SelectablePracticeCardProps) => {
  const icons: Record<Practice["type"], string> = {
    meditation: MeditationIcon,
    breathing: BreathingIcon,
  };

  return (
    <label>
      <Card>
        <Box>
          <Flex gap="2" align="center">
            <Box>
              <img
                src={icons[practice.type]}
                alt="icon"
                width="20px"
                style={{ verticalAlign: "middle" }}
              />
            </Box>
            <Heading size="2" style={{ flex: 1 }}>
              {practice.title}
            </Heading>
            <Checkbox
              onCheckedChange={onChange}
              defaultChecked={checked}
              key={checked.toString()}
            />
          </Flex>
        </Box>
      </Card>
    </label>
  );
};

export default SelectablePracticeCard;
