import { useMutation, useQuery, useQueryClient } from 'react-query';
import { practiceApi } from './practiceApi';
import { Practice } from './types';
import { Pack } from 'features/packs/types';

const useFetchPractices = (data?: { packId?: Pack["_id"] }) => useQuery(['practices', data], () => practiceApi.fetchPractices(data));
const useFetchPractice = (data: { practiceId: Practice["_id"] }) => useQuery(['practice', data], () => practiceApi.fetchPractice(data));

const useAddToFavorites = () => {
    const queryClient = useQueryClient();

    return useMutation(practiceApi.addToFavorites, {
        onSuccess: () => {
            queryClient.invalidateQueries(['practices', 'practice']);
        },
    });
};

export const usePracticeQuery = { useFetchPractices, useFetchPractice, useAddToFavorites };