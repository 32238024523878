import { usePracticeQuery } from "../../features/practices/usePracticeQuery";
import { useAuthContext } from "../../features/auth/useAuthContext";
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
} from "@radix-ui/themes";
import {
  CustomDialog,
  TextSecondary,
  useCustomDialog,
  Wrapper,
} from "components";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { Practice } from "features/practices/types";
import { LockClosedIcon } from "@radix-ui/react-icons";
import PracticeCard from "components/PracticeCard";
import { useState } from "react";
import PracticeListDialog from "./PracticeListDialog";
import InlineCalendar from "./InlineCalendar";
import { Day, User } from "features/user/types";
import { useUserQuery } from "features/user/useUserQuery";
import PlanPaywall from "./PlanPaywall";
import { usePlanQuery } from "features/plans/usePlanQuery";

const RoutinePage = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<Practice["period"]>();
  const practiceListDialogState = useCustomDialog<Practice["_id"][]>();
  const practicesQuery = usePracticeQuery.useFetchPractices();
  const practices = practicesQuery.data;
  const { user } = useAuthContext();
  const [routine, setRoutine] = useState<User["routine"] | undefined>(
    user?.routine
  );
  const routineQuery = useUserQuery.useUpdateRoutine();
  const [dayOfWeek, setDayOfWeek] = useState<Day>("mon");
  const planPaywallDialog = useCustomDialog();
  const plansQuery = usePlanQuery.useFetchPlans();

  const periods: {
    label: string;
    key: Practice["period"];
  }[] = [
    {
      label: "Mattina",
      key: "morning",
    },
    {
      label: "Pomeriggio",
      key: "afternoon",
    },
    {
      label: "Sera",
      key: "evening",
    },
  ];

  const addPractice = (period: Practice["period"]) => {
    if (user?.hasPaidSubscription) {
      practiceListDialogState.open(routine?.[dayOfWeek][period]);
      setSelectedPeriod(period);
    } else {
      planPaywallDialog.open();
    }
  };

  const handleOnAdd = (ids: Practice["_id"][]) => {
    practiceListDialogState.close();
    if (!selectedPeriod) return;
    setRoutine((prevRoutine) => {
      if (!prevRoutine) return;
      prevRoutine[dayOfWeek][selectedPeriod] = ids;
      routineQuery.mutateAsync(prevRoutine);
      return prevRoutine;
    });
  };

  if (!routine) return <>error retrieving information</>;

  return (
    <Wrapper size="sm" mt="4">
      <Box pb="4">
        <Heading
          size="5"
          align="center"
          style={{ textTransform: "capitalize" }}
          mb="4"
        >
          {dayjs(new Date()).format("dddd, D MMMM")}
        </Heading>
        {/* <TextSecondary as="p" align="center">
          Ecco le tue pratiche di oggi
        </TextSecondary> */}
        <InlineCalendar
          onChange={(day) => {
            setDayOfWeek(day);
          }}
        />
      </Box>

      {periods.map((period) => (
        <Box key={period.key} pb="5">
          <Flex direction="row" pb="3" justify="between" align="center">
            <TextSecondary size="2" weight="bold">
              {period.label}
            </TextSecondary>
            {routine[dayOfWeek][period.key].length !== 0 && (
              <Button
                variant="ghost"
                size="1"
                onClick={() => {
                  addPractice(period.key);
                }}
                mr="0.5" /* fix */
              >
                <TextSecondary as="span">Modifica</TextSecondary>
              </Button>
            )}
          </Flex>

          {practicesQuery.isLoading ? (
            <div>Loading...</div>
          ) : (
            <Grid gap="3">
              {routine[dayOfWeek][period.key].length ? (
                <>
                  {practices
                    ?.filter(({ _id }) =>
                      routine[dayOfWeek][period.key].includes(_id)
                    )
                    .map((practice) => (
                      <PracticeCard
                        key={practice._id}
                        practice={practice}
                        variant="basic"
                      />
                    ))}
                </>
              ) : (
                <Box
                  style={{
                    border: "2px dashed #ffffff1a",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addPractice(period.key);
                  }}
                >
                  <Flex gap="3" align="center" py="5" justify="center">
                    <TextSecondary size="3" weight="bold">
                      Aggiungi pratica{" "}
                      {!user?.hasPaidSubscription && <LockClosedIcon />}
                    </TextSecondary>
                  </Flex>
                </Box>
              )}
            </Grid>
          )}
        </Box>
      ))}
      <PracticeListDialog
        dialogState={practiceListDialogState}
        onAdd={handleOnAdd}
        max={3}
      />
      <CustomDialog dialogState={planPaywallDialog}>
        {plansQuery.data && (
          <PlanPaywall
            plan={plansQuery.data[0]}
            feature="routine"
          />
        )}
      </CustomDialog>
    </Wrapper>
  );
};

export default RoutinePage;
