import { Box } from "@radix-ui/themes";
import { MarginProps } from "@radix-ui/themes/dist/cjs/props/margin.props";
import { ReactNode } from "react";

interface WrapperProps extends MarginProps {
  size: "lg" | "md" | "sm";
  children: ReactNode;
}

const Wrapper = ({ size, children, ...margins }: WrapperProps) => {
  const maxWidth = {
    lg: "1200px",
    md: "800px",
    sm: "500px",
  };
  return (
    <Box
      px="1"
      maxWidth={maxWidth[size]}
      style={{ marginLeft: "auto", marginRight: "auto" }}
      {...margins}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
