import api from 'api';
import { Pack } from './types';

export const fetchPacks = (): Promise<Pack[]> =>
    api.get(`/packs`);

export const fetchPack = ({ packId }: { packId: Pack["_id"] }): Promise<Pack> =>
    api.get(`/packs/${packId}`);

export const packApi = {
    fetchPacks,
    fetchPack,
};