import { Login } from '../auth/types'
import { User } from '../user/types';
import endpoints from 'constants/endpoints';
import api from 'api';

const login = (data: Login): Promise<{ token: string }> =>
    api.post(endpoints.AUTH_LOGIN, data);

const signup = (data: { name: string; surname: string; email: string; password: string }): Promise<User> =>
    api.post(endpoints.AUTH_SIGNUP, data);

const forgotPassword = (email: string): Promise<any> =>
    api.post(endpoints.AUTH_FORGOT_PASSWORD, { email });

export const authApi = {
    login,
    signup,
    forgotPassword,
};