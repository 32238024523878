import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import LoadingPage from "pages/LoadingPage";
import { Pack } from "./types";
import { usePackQuery } from "./usePackQuery";

interface PacksContextType {
  packs: Pack[];
}

const PacksContext = createContext<PacksContextType | undefined>(undefined);

interface PacksProviderProps {
  children: ReactNode;
}

export const PacksProvider = ({ children }: PacksProviderProps) => {
  const [packs, setPacks] = useState<Pack[]>([]);
  const packQuery = usePackQuery.useFetchPacks();

  useEffect(() => {
    if (!packQuery.data || packQuery.isError) return;
    setPacks(packQuery.data);
  }, [packQuery.isError, packQuery.data]);

  return (
    <PacksContext.Provider value={{ packs }}>
      {packQuery.isLoading ? <LoadingPage /> : children}
    </PacksContext.Provider>
  );
};

export const usePacksContext = (): PacksContextType => {
  const context = useContext(PacksContext);
  if (!context) {
    throw new Error("usePacksContext must be used within an PacksProvider");
  }
  return context;
};
