import { Pack } from 'features/packs/types';
import { Practice } from './types';
import api from 'api';

const fetchPractices = (params?: { packId?: Pack["_id"] }): Promise<Practice[]> =>
    api.get('/practices', { params });

const fetchPractice = ({ practiceId }: { practiceId: Practice["_id"] }): Promise<Practice> =>
    api.get(`/practices/${practiceId}`);

const addToFavorites = ({ practiceId, isFavorite }: { practiceId: Practice["_id"], isFavorite: boolean }): Promise<Practice> =>
    api.post(`/practices/${practiceId}/favorites`, { isFavorite });

export const practiceApi = {
    fetchPractices,
    fetchPractice,
    addToFavorites,
};