import { Button, Grid } from "@radix-ui/themes";
import { TextSecondary } from "components";
import CustomDialog, { CustomDialogState } from "components/CustomDialog";
import SelectablePracticeCard from "components/SelectablePracticeCard";
import { Practice } from "features/practices/types";
import { usePracticeQuery } from "features/practices/usePracticeQuery";
import { useEffect, useState } from "react";

interface PracticeListDialogProps {
  dialogState: CustomDialogState<Practice["_id"][]>;
  onAdd: (ids: Practice["_id"][]) => void;
  max: number;
}

const PracticeListDialog = ({
  dialogState,
  onAdd,
  max,
}: PracticeListDialogProps) => {
  const practicesQuery = usePracticeQuery.useFetchPractices();
  const practices = practicesQuery.data;
  const [idsToAdd, setIdsToAdd] = useState<Practice["_id"][]>([]);

  const handleOnAdd = () => {
    onAdd(idsToAdd);
  };

  useEffect(() => {
    setIdsToAdd(dialogState.state ?? []);
  }, [dialogState.state]);

  return (
    <CustomDialog
      dialogState={dialogState}
      title="Lista pratiche"
      description="Seleziona le pratiche da aggiungere"
    >
      <Grid gap="2" pb="3" maxHeight="70vh" overflow="auto">
        {practices?.map((practice) => (
          <SelectablePracticeCard
            key={practice._id}
            practice={practice}
            checked={idsToAdd?.includes(practice._id)}
            onChange={() => {
              setIdsToAdd((prevIds) => {
                const index = prevIds.indexOf(practice._id);
                if (index > -1) {
                  return prevIds.filter((id) => id !== practice._id);
                } else {
                  if (prevIds.length < max) return [...prevIds, practice._id];
                  else return prevIds;
                }
              });
            }}
          />
        ))}
      </Grid>

      <TextSecondary size="2">{idsToAdd.length} selezionati</TextSecondary>
      <Button onClick={handleOnAdd} size="3" style={{ width: "100%" }} mt="2">
        Aggiungi
      </Button>
    </CustomDialog>
  );
};

export default PracticeListDialog;
